.dialPadGrid, .voterDisplayGrid {
    display: flex;
    justify-content: center;
    border: solid black 1px;
    padding: 20px;
    border-radius: 20px;
    margin: 15px;
}

.dialPad, .voterIcon {
    margin: 10px !important
}

