@font-face {
  font-family: 'SpinCycle3D';
  src: local('SpinCycle3D'), url(./fonts/SpinCycle3D-9ej.ttf) format('truetype')
}

body {
  margin:  20px 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  justify-content: center;
}

.nav-heading {
  color: white;
  font-size: 55px;
  font-family: 'SpinCycle3D';
  line-height: 1.38;
}

.entry-title {
  font-family: 'SpinCycle3D' !important;
  src: url('/src/fonts/SpinCycle3D-9ej.ttf');
  font-size: 40px;
}

.newRoom {
  display: flex;
  justify-content: center;
}

.MuiCard-root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.dialPad.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  margin: 10px;
} */

/* .MuiButton-root {
  margin: 10px !important;
} */